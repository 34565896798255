
"use strict";

var appAccordion = function() {
    var acc = document.getElementsByClassName('accordion');

    for (var i = 0; i < acc.length; i++) {

        var container;

        (function (i) {
            acc[i].addEventListener('click', function (e) {

                var selected = acc[i].nextElementSibling;

                if(container !== undefined && container !== acc[i]){
                    container.nextElementSibling.style.height = '0px';
                    container.classList.remove('clicked');
                }

                if (acc[i].classList.contains('clicked')){
                    selected.style.height = '0px';
                    acc[i].classList.remove('clicked');
                }
                else{
                    selected.style.height = selected.getElementsByTagName('P')[0].offsetHeight + 'px';
                    acc[i].classList.add('clicked');
                }

                container = acc[i];

            });
        })(i)

    }
};

var appMobileMenu = function() {

    //show menu
    $('#burger').on('click', function(){
        $('.main-header').toggleClass('mobile');
    });

    // close menu after choose item
    var mobileHeader = $('.main-header');
    mobileHeader.find('a').on('click', function(){
        if(mobileHeader.hasClass('mobile')){
            mobileHeader.removeClass('mobile');
        }
    })
};

var appScroll = function() {

    if($(window).width() < 992){
        $('body').addClass('mobile-view');
    } else {
        $('body').removeClass('mobile-view');
    }

    var offsetValue;

    if($('body').hasClass('mobile-view')){
        offsetValue = -70;
    } else {
        offsetValue = -135;
    }

    $(window).on('resize', function(){

        if($(window).width() < 992){
            $('body').addClass('mobile-view');
        } else {
            $('body').removeClass('mobile-view');
        }


        if($('body').hasClass('mobile-view')){
            offsetValue = -70;
        } else {
            offsetValue = -135;
        }
    });

    $.scrollIt({
        upKey: 38,             // key code to navigate to the next section
        downKey: 40,           // key code to navigate to the previous section
        easing: 'ease-out',      // the easing function for animation
        scrollTime: 800,       // how long (in ms) the animation takes
        activeClass: 'active', // class given to the active nav element
        onPageChange: null,    // function(pageIndex) that is called when page is changed
        topOffset: offsetValue           // offste (in px) for fixed top navigation
    });

    $(function() {

        $(window).on('load', function(){
            $(".intro").find('.packshot').addClass('animation');
        });

        $(".intro").scrolling({offsetTop: 0});
        $('.intro').on('scrollin', function (event, $all_elements) {
            $(this).find('.packshot').addClass('animation');
        });
        $('.intro').on('scrollout', function (event, $all_elements) {
            $(this).find('.packshot').removeClass('animation');
        });

        $(".action").scrolling({offsetTop: -400});
        $('.action').on('scrollin', function (event, $all_elements) {
            $(this).find('.start').addClass('animation');
        });
        $('.action').on('scrollout', function (event, $all_elements) {
            $(this).find('.start').removeClass('animation');
        });

        $(".what-is").scrolling({offsetTop: -200});
        $('.what-is').on('scrollin', function (event, $all_elements) {
            $(this).find('.image').addClass('animation');
        });
        $('.what-is').on('scrollout', function (event, $all_elements) {
            $(this).find('.image').removeClass('animation');
        });

        $(".effectiveness").scrolling({offsetTop: -400});
        $('.effectiveness').on('scrollin', function (event, $all_elements) {
            $(this).find('.animationStart').addClass('animation');
        });
        $('.effectiveness').on('scrollout', function (event, $all_elements) {
            $(this).find('.animationStart').removeClass('animation');
        });

        $(".ingredients").scrolling({offsetTop: -400});
        $('.ingredients').on('scrollin', function (event, $all_elements) {
            $(this).find('.animationStart').addClass('animation');
        });

        $('.ingredients').on('scrollout', function (event, $all_elements) {
            $(this).find('.animationStart').removeClass('animation');
        });


        $(".synergy-action").scrolling({offsetTop: -300});
        $('.synergy-action').on('scrollin', function (event, $all_elements) {
            $(this).find('.synergy-animation').addClass('animation');
        });

        $('.synergy-action').on('scrollout', function (event, $all_elements) {
            $(this).find('.synergy-animation').removeClass('animation');
        });

        $(".icon-container").scrolling({offsetTop: 0});
        $('.icon-container').on('scrollin', function (event, $all_elements) {
            $(this).find('.show-icon').addClass('animation');
        });

        $('.icon-container').on('scrollout', function (event, $all_elements) {
            $(this).find('.show-icon').removeClass('animation');
        });




    });
};

var appResults = function() {
    var results = $('.results');
    $('#show-results').on('click', function(evt){
        if(results.is(':visible')){
            results.slideUp(400);
            evt.preventDefault();
        } else {
            results.slideDown(800);
            evt.preventDefault();
        }
    });

    $(window).on('resize', function(){
        if($(this).width() < 992){
            results.slideUp(400);
        }
    })
};

var appCookies = function() {
    if ($.cookie('giodoCookies') !== '1') {
        $('.cookies-alert').css('display', 'block');
    }

    $('.cookies-alert button').on('click', function () {
        $('.cookies-alert').slideToggle('slow', function () {
            $.cookie('giodoCookies', '1', {expires: 365});
        });
    });
};

var appScrollbars = function() {
    var wrapSelector = $('.inner-col');
    wrapSelector.perfectScrollbar();

   window.addEventListener('resize', function() {
        wrapSelector.perfectScrollbar('update');
    });
};

function rodo(){

	if(localStorage.getItem("rodoAgree") !== "yes") {
		$('body').addClass('show-rodo');
	}

	$('#show-more').click(function(e){
		$('.part-2').slideDown();
		e.preventDefault();
	});

	$('.button.rodo-agree').click(function(e){
		localStorage.setItem("rodoAgree", "yes");
		$('.modal-rodo-outer').fadeOut();
		e.preventDefault();
	});
}

$(function() {
    rodo();
    appCookies();
    appMobileMenu();
    appScroll();
    appResults();
    appAccordion();
    appScrollbars();
});